<template>
    <v-menu
        ref="menu"
        v-model="menu"
        class="range-datepicker__menu-block"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y>
        <template #activator="{ on }">
            <v-text-field
                class="main-page-range-datepicker-field"
                readonly
                outlined
                :value="dateRangeText"
                :label="label"
                v-on="on" />
            <!-- <div class="v-messages error--text" role="alert">
                <div class="v-messages__wrapper">
                    <div class="v-messages__message">{{ errorMessages }}</div>
                </div>
            </div> -->
        </template>
        <v-date-picker
            :value="value"
            :first-day-of-week="1"
            range
            no-title
            type="month"
            color="#E61E4D"
            :locale="lang === 'ru' ? 'ru-RU' : 'en-US'"
            @input="updateDate" />
    </v-menu>
</template>

<script>
    import { mapState } from 'vuex';
    import moment from '@/plugins/moment'

    export default {
        props: {
            value: {
                type: Array,
                default: () => { [] },
            },
            label: {
                type: String,
                default: '',
            },
            errorMessages: {
                type: String,
                default: '',
            },
        },
        data: () => ({
            menu: false,
            modal: false,
            menu2: false,
        }),
        computed: {
            ...mapState('settings', ['lang']),
            dateRangeText() {
                if (this.value.length !== 0) {
                    let newValue = [];
                    if (this.value[0]) {
                        newValue.push(moment(this.value[0]).format('MMMM YY'));
                    }
                    if (this.value[1]) {
                        if(this.value[0] > this.value[1]) {
                            newValue.unshift(moment(this.value[1]).format('MMMM YY'));
                        } else {
                            newValue.push(moment(this.value[1]).format('MMMM YY'));
                        }
                        
                    }
                    // return 'с ' + newValue.join(' по ');
                    if (newValue[0] !== newValue[1]) {
                        return newValue.join(' - ');
                    } else {
                        return newValue[0]
                    }
                } 
                return '';
            },
        },
        methods: {
            updateDate(value) {
                this.$emit('change', value);
                if (value.length > 1 ) {
                    this.menu = false;
                }
            },
        },
    };
</script>

<style lang="scss">
.main-page-range-datepicker-field {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #90949A !important;
    .v-input__slot {
        border-radius: 10px;
        margin: 0;
        padding: 0;
    }
    &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
        padding: 0;
    }
    &.v-text-field input {
        padding: 0;
        color: #90949A;
    }
    input {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #273155 !important;
        width: 100%;
        cursor: pointer;
    }
    &.v-text-field--outlined.v-input--is-focused fieldset,
    &.v-text-field--outlined.v-input--has-state fieldset {
        border: none !important;
    }
    &.theme--light.v-label {
        left: 1px;
    }
    &.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: #fff;
        border: none;
        }

    &.v-text-field--outlined > .v-input__control > .v-input__slot {
        @media all and (min-width: 768px) {
            min-height: 0;
        }
    }
    &.v-text-field.v-text-field--enclosed .v-text-field__details {
        min-height: 0;
        margin-bottom: 0;
    }
    .v-label.theme--light {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #cbcfd4;
        top: 2px;
        &.v-label--active {
            display: none;
        }
    }
}

</style>

