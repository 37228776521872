<template>
    <div class="mainpage-filters" v-if="translates && translates[langUrl]">
        <div class="mainpage-filters__contaiener">
            <div class="mainpage-filters__name">{{ translates[langUrl].filterTitle_1[lang] }}</div>
            <!-- <v-text-field
                class="mainpage-filters__field"
                placeholder="Куда едете?"
                @change="updateFilterSearch"
            /> -->
            <v-select
                class="mainpage-filters__field"
                :placeholder="translates[langUrl].placeholder_1[lang]"
                :value="filter.directions"
                :items="[{ code: null, title: translates[langUrl].allDirections[lang] }, ...countries]"
                item-text="title"
                item-value="code"
                @change="updateFilterDirections"
            ><template #append>
                <img src="../../assets/DownArrow.svg" class="base-select__append" />
            </template>
            </v-select>
        </div>
        <div class="mainpage-filters__contaiener">
            <div class="mainpage-filters__name">{{ translates[langUrl].filterTitle_2[lang] }}</div>
            <!-- <v-text-field
                class="mainpage-filters__field"
                placeholder="Когда отправляесмя?"
            /> -->
            <RangeDatePicker
                :label="translates[langUrl].placeholder_2[lang]"
                type="month"
                :value="filter.dates"
                @change="updateFilterDates" />
        </div>
        <div class="mainpage-filters__contaiener">
            <div class="mainpage-filters__name">{{ translates[langUrl].filterTitle_3[lang] }}</div>
            <!-- <v-select
                class="mainpage-filters__field"
                placeholder="Все приключения"
                :value="filter.tripType"
                :items="types"
                item-text="title"
                item-value="code"
                @change="updateFilterType"
            ><template #append>
                <img src="../../assets/DownArrow.svg" class="base-select__append" />
            </template>
            </v-select> -->
            <!-- <v-select
                class="mainpage-filters__field"
                placeholder="Выберите тип"
                :value="filter.tripType"
                :items="types"
                item-text="title"
                item-value="code"
                multiple
                persistent-hint
                @change="updateFilterType"
            ><template #append>
                <img src="../../assets/DownArrow.svg" class="base-select__append" />
            </template>
            </v-select> -->
            <v-select
                class="mainpage-filters__field"
                :placeholder="translates[langUrl].placeholder_3[lang]"
                :value="filter.price"
                :items="selectPrice[lang]"
                item-text="label"
                item-value="value"
                @change="updateFilterPrice"
            ><template #append>
                <img src="../../assets/DownArrow.svg" class="base-select__append" />
            </template>
            </v-select>
        </div>
        <div class="mainpage-filters__find" @click="tripList"><img class="mainpage-filters__find__img" src="../../assets/SearchGlass.svg" /></div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import RangeDatePicker from '../common/MainPageRangeDatePicker.vue';

    export default {
        name: 'MainpageFilters',
        components: {
            RangeDatePicker
        },
        data: () => ({
            filter: { 
                directions: null,
                dates: [], 
                tripType: [],
                price: [],
            },
            selectPrice: {
                'ru': [
                    { label: 'не важно', value: null },
                    { label: 'до 50 000 руб.', value: ['<', 50000] },
                    { label: '50 000 руб. - 100 000 руб.', value: [50000, 100000] },
                    { label: '100 000 руб. - 250 000 руб.', value: [100000, 250000] },
                    { label: '250 000 руб. - 500 000 руб.', value: [250000, 500000] },
                    { label: '500 000 руб. +', value: ['>', 500000] },
                ],
                'en': [
                    { label: 'any', value: null },
                    { label: 'up to 1000$', value: ['<', 1000] },
                    { label: '1000$ - 2000$', value: [1000, 2000] },
                    { label: '2000$ - 3000$', value: [2000, 3000] },
                    { label: '3000$ - 4000$', value: [3000, 4000] },
                    { label: '4000$ +', value: ['>', 4000] },
                ]
            },
            langUrl: 'MainpageFilters'
        }),
        computed: {
            ...mapState('dictionaries', ['types', 'countries', 'cities']),
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        methods: {
            async tripList() {
                if (this.filter.dates[1] && this.filter.dates[0] > this.filter.dates[1]) {
                    let value = []
                    value[0] = this.filter.dates[1]
                    value[1] = this.filter.dates[0]
                    this.filter.dates = value
                }
                await this.$router.push({ name: 'trip-list', params: { filter: { directions: this.filter.directions, dates: this.filter.dates, tripType: this.filter.tripType, price: this.filter.price} } });
            },
            updateFilterDirections(value) {
                this.filter.directions = value;
            },
            updateFilterType(value) {
                this.filter.tripType = value;
            },
            updateFilterPlace(value) {
                this.filter.place = value;
            },
            updateFilterPrice(value) {
                this.filter.price = value;
            },
            updateFilterDates(value) {
                this.filter.dates = value;
            },
        }
    }
</script>

<style lang="scss">
.mainpage-filters {
    background: #FFFFFF;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
    border-radius: 37.5px;
    width: 783px;
    height: 64px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    padding-left: 30px;
    font-family: Inter;
    font-style: normal;
    &__contaiener {
        width: 220px;
        padding: 0 30px;
        margin: 12px 0;
        border-left: 1px solid #E5E6E9;
        text-align: left;
        &:first-of-type {
            border-left: none;
        }
        &:nth-of-type(2) {
            width: 240px;
        }
    }
    &__name {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #3A4256;
    }
    &__field {
        text-overflow: ellipsis;
            overflow: hidden;
        &.v-text-field {
            margin-top: 0;
            padding-top: 0;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &.v-text-field input {
            font-weight: 300;
            font-size: 15px;
            line-height: 150%;
            letter-spacing: -0.02em;
            // color: #273155;
            color: #90949A;
            padding: 0;
        }
        .base-select__append {
            margin-top: 4px;
            margin-left: -30px;
        }
        .v-select__selection--comma {
            margin: 0;
        }
        &.theme--light.v-select .v-select__selections {
            font-weight: 300;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #90949A;
        }
    }
    .v-input__slot::before {
        border: none !important;
    } 
    .v-input__slot::after {
        border: none !important;
    }
    &__find {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 50px;
        height: 50px;
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%), #FFFFFF;
        border-radius: 50%;
        margin: 7px 0 7px 15px;
        cursor: pointer;
        &__img {
            width: 20px;
            height: 20px;
        }
    }
}
</style>